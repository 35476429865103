@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,700;1,100;1,200;1,300;1,400;1,500;1,700&display=swap');


* {
    margin: 0;
    padding: 0;
}

.App, html, #root, body {
  /*   background-color: black;  for debugging */
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    
}

:root {
    --main-bg-color: #f2f2f2;
    --alt-bg-color: #ffffff;
    --main-font: 'Roboto', sans-serif;
    --main-color: #0571cb;
    --main-nav-color: #ffffff;
    --main-border-color: #e0e0e0;

    --confirm-bg-color: #06cb05;

    --secondary-font: 'Montserrat', sans-serif;
    
    --main-text-color: #6b6b6b;
    --alt-text-color: #ffffff;
    
    --card-surface: #ffffff;
    --box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%);
    --error-color: #e70000;

    --main-border: 1px solid var(--main-border-color);
}

.hidden {
    display: none !important;
}

